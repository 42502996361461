/* src/styles/components.css */

.btn {
    @apply font-bold py-2 px-4 rounded transition duration-150 ease-in-out;
}
.btn-primary {
    @apply bg-primary text-white hover:bg-primary-dark;
}
.btn-secondary {
    @apply bg-secondary text-text hover:bg-secondary-dark;
}

.input {
    @apply w-full px-3 py-2 border rounded-md transition duration-150 ease-in-out;
    @apply border-secondary focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50;
    @apply bg-white dark:bg-darkMode text-text dark:text-text-dark placeholder-secondary dark:placeholder-darkMode-lighter;
    @apply dark:border-gray-500 dark:focus:border-primary;
}

.select {
    @apply w-full px-3 py-2 border rounded-md transition duration-150 ease-in-out;
    @apply border-secondary focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50;
    @apply bg-white dark:bg-darkMode text-text dark:text-text-dark;
    @apply dark:border-gray-200 dark:focus:border-primary;
}

.card {
    @apply bg-white dark:bg-darkMode-light rounded-lg shadow-md overflow-hidden;
}

.text-title {
    @apply text-2xl font-bold text-text dark:text-text-dark;
}

.text-subtitle {
    @apply text-xl font-semibold text-text dark:text-text-dark;
}

.text-body {
    @apply text-base text-text dark:text-text-dark;
}

.nav-item {
    @apply px-3 py-2 rounded-md text-text-light dark:text-text-dark hover:bg-secondary dark:hover:bg-darkMode transition duration-150 ease-in-out;
}

.nav-item-active {
    @apply bg-secondary dark:bg-darkMode text-text dark:text-text-dark;
}