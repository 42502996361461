@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import './components.css';
@import 'tailwindcss/utilities';

body {
    @apply bg-background text-text;
}

.dark body {
    @apply bg-background-dark text-text-dark;
}